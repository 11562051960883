<template>
  <div class="cards">
    <app-card
      title="Spacemob"
      tag="Development"
      textColor="#31B28D"
      className="spacemob"
      url="https://www.spacemob.co/"
    ></app-card>
    <app-card
      title="Giving"
      tag="Development"
      textColor="#7955B4"
      className="giving"
      url="https://www.giving.sg/"
    ></app-card>
    <app-card
      title="Mrt-tan"
      tag="Development, Design"
      textColor="rgb(188, 100, 27)"
      className="mrttan"
      url="https://mrt-tan.com"
    ></app-card>
    <app-card
      title="Watchcrypto"
      tag="Development, Design"
      textColor="#23d260"
      className="watchcrypto"
      url="https://watchcrypto.today"
    ></app-card>
    <app-card
      title="Innocellence"
      tag="Development"
      textColor="#EF4B46"
      className="innocellence"
      url="http://www.innocellence.com/"
    ></app-card>
  </div>
</template>

<script>
import Card from '@/components/Card'

export default {
  // eslint-disable-next-line
  name: 'Header',
  data () {
    return {}
  },
  components: {
    'app-card': Card
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.cards {
  $max-width: 960px;

  width: 100%;
  max-width: $max-width;
  display: flex;

  @media (max-width: $max-width) {
    flex-flow: row wrap;
  }
}
</style>
