<template>
  <div class="wrapper">
    <div style="grid-column: 2 / 7">
      <h2>{{ about_header }}</h2>
      <p>I am a full stack engineer based in Singapore.</p>
      <p>
        I have a Bachelors Degree in Information Technology; however a lot of
        what I know of on the web today has been self-taught.
      </p>
    </div>
    <div style="grid-column: 8 / 12">
      <img src="/assets/profile.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'AboutComponent',
  data () {
    return {
      about_header: 'About me',
      profile_pic: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.5rem;

  text-align: left;
  margin: 0 auto;
  max-width: 90vw;
}
</style>
