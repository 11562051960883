<template>
  <div id="app">
    <app-header></app-header>
      <router-view />
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from '@/sections/Header.vue'
import Footer from '@/sections/Footer.vue'

export default {
  name: 'App',
  components: {
    'app-header': Header,
    'app-footer': Footer
  }
}

const WebFont = require('webfontloader')

WebFont.load({
  google: {
    families: ['Fira Sans', 'Roboto']
  }
})
</script>

<style lang='scss'>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1a535c;
  margin-top: 60px;
  max-width: 100%;

  margin: 0 auto;

  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

header {
  font-family: 'GFS Didot', serif;
}
</style>
