<template>
  <span v-if="{ time }">~{{ time }} min read</span>
</template>

<script>
export default {
  name: 'ReadingTime',
  data: function () {
    return {}
  },
  props: {
    time: {
      type: Number,
      default: 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
