<template>
  <div>
      <app-body></app-body>
  </div>
</template>

<script>
import Body from '@/sections/Body'

export default {
  // eslint-disable-next-line
  name: 'Index',
  data: function () {
    return {
    }
  },
  components: {
    'app-body': Body
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
