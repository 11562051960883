import { createApp, provide, h } from 'vue'
import router from './router'
import App from './App.vue'

// 3rd party utils
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  cache,
  uri: 'https://gql.hashnode.com/'
})

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})

app.use(router)
app.use(VueGtag, {
  config: { id: 'UA-91308668-4' }
})
app.use(VueAxios, axios)
// app.use(require('vue-moment'))

app.mount('#app')
