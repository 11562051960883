<template>
  <div>
    <footer>
      <div class="footer">
        <p>
          &copy;
          <router-link to="/">jacobtan.co</router-link>&nbsp;2019-{{
            currentYear()
          }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Footer',
  data () {},
  methods: {
    currentYear: () => {
      return new Date().getFullYear()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.footer {
  height: 40px;
  width: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  bottom: 0;

  p {
    font-size: 0.9rem;
    text-align: center;
    font-weight: bold;

    a {
      color: #1a535c;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
